import { Sidebar } from "./sidebar";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { NoticeBoard, StaffProfile } from "../components";
import { useEffect, useState } from "react";
import { proxy, readDocument, readDocuments, xmail } from "../endpoints";
import { Feedback } from "../components/modals/feedback";
import axios from "axios";
import { decryptData } from "../functions/crypt";
import { getDocument } from "../endpoints/fb";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import logo from "../assets/images/_logo.png";
import { PortalLocked } from "../components/PortalLocked";

const ADMIN_PROFILE = {
  class_assigned: "Basic 1",
  category: "Lower Primary",
  division: "A",
  first_name: "Admin",
  last_name: "Admin",
  title: "The",
  photo: logo,
};
export default function Layout({ Child }) {
  const [toggleState, setToggle] = useState(false);
  const [isPortalLocked, setPortalState] = useState(false);
  const [academicYear, setAcademicYear] = useState("");
  const [classes, setClasses] = useState([]);
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState({ ...ADMIN_PROFILE });
  const [school, setSchool] = useState({});
  const id = localStorage.getItem("staffID");
  const [students, setStudents] = useState([]);
  const [settings, setSettings] = useState({});
  const [term, setTerm] = useState("");
  const [exams, setExams] = useState([]);
  const [call, shouldCall] = useState(false);
  const dbName = decryptData(localStorage.getItem("DBN"));
  const schoolID = localStorage.getItem("schoolID");
  const [response, setResponse] = useState({
    message: "",
    type: "error",
    open: false,
  });

  useEffect(() => {
    async function readData() {
      try {
        if (!id.includes("@")) {
          const { data } = await axios.get(`${proxy}/staff/${id}`, {
            headers: { dbName: dbName, xmail: xmail },
          });

          setProfile(data);
          await readDocuments({
            path: `/student/${data?.class_assigned}`,
            getData: setStudents,
          });


          readDocuments({
            path: `/exams/${data.class_assigned}?academic_year=${settings.academicYear}&callID=${call}`,
            getData: setExams,
          });

        } else {
          // setProfile(ADMIN_PROFILE);
          await readDocuments({
            path: `/student/${profile.class_assigned}`,
            getData: setStudents,
          });

          readDocuments({
            path: `/exams/${profile.class_assigned}?academic_year=${academicYear || settings.academicYear}&callID=${call}`,
            getData: setExams,
          });
        }
      } catch (err) {
        console.log(err);
      }

      await readDocument({
        path: "/settings",
        id: "standard",
        getData: setSettings,
      });

      await readDocuments({
        path: "/class/",
        getData: setClasses,
      });
    }

    getDocument({
      path: "schoolx",
      id: schoolID,
      getData: setSchool,
    });

    try {
      readDocument({
        path: "/portal",
        id: "standard",
        getData: (data) => {
          setPortalState(data?.isStaffPortalOpen);
          if (data?.isStaffPortalOpen) {
            readData();
          }
        },
      });
    } catch (err) {
      setResponse({
        message: err.message,
        open: true,
        type: "error",
      });
    }
    // notification will be from firebase.
    //eslint-disable-next-line
  }, [id, settings.academicYear, academicYear, profile, call]);

  const switchClass = (id) => {
    const ths = _.find(classes, (cl) => cl.id === id);
    setProfile({
      ...profile,
      class_assigned: ths?.className,
      category: ths?.category,
    });
  };

  const shouldDisable = (category) => {
    let role = localStorage.getItem('role');
    let levelAssigned = localStorage.getItem('levelAssigned');
    
    if(role === "Administrator" || role === "Head Teacher"){
      return false
    }

    if (role === "Level Coordinator" && levelAssigned !== category){
        return true
    }
  }
  const sortStudents = () => {
    const cls = _.filter(students, (std) => std.division === profile?.division);
    return _.sortBy(cls, (cl) => cl.last_name);
  };
  const props = {
    settings: { ...settings, currentTerm: term || settings.currentTerm, academicYear: academicYear || settings.academicYear },
    exams: _.filter(exams, (ex) => ex.division === profile?.division),
    profile,
    students: sortStudents(),
    shouldCall,
  };
  return (
    <>
      {!isPortalLocked ? (
        <PortalLocked logo={school?.logo} banner={school?.banner} />
      ) : (
        <div className="flex flex-1 bg-gray-50">
          <AnimatePresence>
            {toggleState && (
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -100 }}
                className={`${"w-full flex fixed left-0 z-50"} md:hidden`}
              >
                <Sidebar
                  toggleState={toggleState}
                  school={school}
                  close={() => setToggle(false)}
                />
                <div
                  onClick={() => setToggle(false)}
                  className="flex-1 bg-gray-50/30 backdrop-blur-sm"
                ></div>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="hidden md:block">
            <Sidebar
              toggleState={toggleState}
              school={school}
              close={() => setToggle(false)}
            />
          </div>

          <div className="flex flex-col flex-1 w-full relative">
            {/* Top Navigation Bar */}
            <div className="flex-1 w-full mb-2 bg-gray-100 p-3 absolute top-0 flex items-center justify-between space-x-5">
              <div className="flex items-center gap-3 w-fit px-3 md:w-1/3">
                <h3 className="text-sm truncate">
                  Hi, <span className="font-bold">{profile?.last_name}</span> 👋{" "}
                </h3>
                {id?.includes("@") && (
                  <div className="hidden md:flex items-center gap-3">
                    <select
                      className="w-fit px-2"
                      onChange={(e) => switchClass(e.target.value)}
                    >
                      <option value="">Select Class</option>
                      {classes.map((cls, idx) => (
                        <option key={idx} value={cls.id}
                          disabled={shouldDisable(cls.category)}
                        >
                          {cls.className}
                        </option>
                      ))}
                    </select>
                    <select
                      className="w-fit px-2"
                      onChange={(e) =>
                        setProfile({ ...profile, division: e.target.value })
                      }
                    >
                      <option value="A">A</option>
                      <option value="B">B</option>
                    </select>
                    <select className="px-2 w-fit" onChange={(e) => setTerm(e.target.value)}>
                      <option value="">Select Term</option>
                      <option value="First Term">First Term</option>
                      <option value="Second Term">Second Term</option>
                      <option value="Third Term">Third Term</option>
                    </select>

                    <select className="px-2 w-fit" value={academicYear || settings?.academicYear} onChange={(e) => setAcademicYear(e.target.value)}>
                      <option value="">Select Academic Year</option>
                      <option value="2023/24">2023-2024</option>
                      <option value="2024/25">2024-2025</option>
                      <option value="2025/26" disabled>2025-2026</option>
                    </select>
                  </div>
                )}
              </div>

              <div className="flex justify-end items-center gap-5 mr-2">
                <button
                  className="w-10 h-10 flex items-center justify-center relative"
                  onClick={() => setOpen(!open)}
                // disabled
                >
                  <HiOutlineBellAlert className="text-4xl text-gray-500" />
                  <span className="absolute top-0 right-0 w-4 h-4 bg-red-700 text-center text-[10px] rounded-full text-white">
                    1
                  </span>
                </button>
                <StaffProfile
                  profile={profile}
                  toggle={toggleState}
                  setToggle={setToggle}
                />
              </div>
            </div>
            {/* child Components. */}
            <div className="flex bg-white flex-1 mt-[60px]  overflow-y-auto">
              <Child {...props} />
            </div>

            {response.open && (
              <Feedback
                message={response.message}
                type={response.type}
                setOpen={() => setResponse({ open: false })}
              />
            )}

            {open && <NoticeBoard messages={[]} />}
          </div>
        </div>
      )}
    </>
  );
}

import { _courses, subjects } from "../assets/data/courses";
import { useState, useRef } from "react";
import _ from "lodash";
import axios from "axios";
import { proxy, uploadData, updateData, xmail, dbName } from "../endpoints";
import { Feedback } from "../components/modals/feedback";
import { v4 as idv4 } from "uuid";
import { calculateAggregate } from "../functions";
import { Spin } from "../components/loader";

export default function Midterm({ students, settings, profile }) {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(false)
  const [testType, setTestType] = useState("Mock Exams");
  const [remarks, setRemarks] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const form = useRef();
  const [reports, setReports] = useState([]);
  const [id, setID] = useState("");
  const [selected, setSelected] = useState(null);
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });

  const SUBJECTS = ["Basic 9", "Basic 8", "Basic 7"].includes(profile?.class_assigned)
    ? subjects
    : _courses;

  const reportPath = profile?.class_assigned === "Basic 9" ? "mock" : "midterm";

  const search = (info) => {
    
    return profile.class_assigned !== "Basic 9"
      ? info.term === settings.currentTerm &&
          info.current_class === profile.class_assigned &&
          info.academicYear === settings.academicYear
      : info.term === settings.currentTerm &&
          info.academicYear === settings.academicYear &&
          info.examType === testType;
  };

  const getScore = (subject, mark, isCore) => {
    const isAdded = _.find(scores, (sc) => sc.subject === subject);
    if (isAdded === undefined) {
      // new mark entered
      setScores([
        ...scores,
        {
          subject,
          mark: parseFloat(mark),
          isCore,
        },
      ]);
    } else {
      scores[scores.indexOf(isAdded)] = { subject, mark: parseFloat(mark), isCore };
      setScores(scores);
    }
  };

  const uploadResults = (event) => {
    event.preventDefault();
    setLoading(true)

    const coreSubjects = _.filter(scores, sc => sc.isCore === true)
    const rawScore = _.sumBy(coreSubjects, (sc) => sc.mark);

    // calculate aggregates.

    const newReport = {
      id: new Date().getTime().toString(),
      term: settings.currentTerm,
      current_class: selected.current_class,
      date_processed: new Date().toISOString(),
      results: scores,
      academicYear: settings.academicYear,
      remarks,
      examType: testType,
      rawScore,
      aggregate: calculateAggregate(scores),
    };

    if (updateMode) {
      let data = {};
      let found = false;

      // find data for this term and update.
      for (var rp of reports) {
        for (var info of rp.report) {
          if (search(info)) {
            const report = rp.report;
            report[rp.report.indexOf(info)] = newReport;

            data = { ...rp, ...report[0] };
            delete data.id;
            found = true;
            break;
          }
        }
      }

      if (found === false) {
        const oldReport = reports[0]?.report || [];
        data = {
          student_id: selected.student_id,
          photo: selected.photo || "xyz",
          phone: selected.contact_1 || selected.contact_2,
          academicYear: settings.academicYear,
          name: selected.last_name + " " + selected.other_names,
          examType: testType,   
          report: [...oldReport, newReport],
        };
      }

      updateData({
        path: `/${reportPath}`,
        id,
        data: { ...data },
      })
        .then(() => {
          setResponse({
            type: "success",
            message: "Data Successfully Updated",
            open: true,
          });
        })
        .catch((err) => {
          setResponse({
            type: "error",
            message: err.message,
            open: true,
          });
        }).finally(() => setLoading(false))
    } else {
      const data = {
        student_id: selected.student_id,
        photo: selected.photo,
        phone: selected.contact_1 || selected.contact_2,
        academicYear: settings.academicYear,
        name: selected.last_name + " " + selected.other_names,
        id: idv4().slice(0, 30).replaceAll("-", ""),
        report: [{ ...newReport }],
      };
      uploadData({
        path: `/${reportPath}/`,
        data,
      })
        .then(() => {
          setResponse({
            type: "success",
            message: "Data Successfully Uploaded",
            open: true,
          });
          
        })
        .catch((err) => {
          const { data } = err.response;
          setResponse({
            type: "error",
            message: data.message,
            open: true,
          });
        }).finally(() => setLoading(false))
    }

    // find saw score

    // console.log(newReport)
  };

  const selectStudent = async (student) => {
    setSelected(student);
    try {
      const { data } = await axios.get(
        `${proxy}/${reportPath}/${student.student_id}?group=True`,
        {
          headers: {
            dbName,
            xmail,
          },
        }
      );

      if (data !== null) {
        setUpdateMode(true);

        for (var dt of data) {
          setID(dt.id);
          for (var info of dt.report) {
            if (search(info)) {
              setScores(info.results);

              for (let rs of info.results) {
                for (let field of form.current.children) {
                  if (field.children[1].name === rs.subject) {
                    field.children[1].value = rs.mark;
                  }
                }
              }
              break;
            }
          }
        }
        setReports(data);
      } else {
        setScores([]);
        setUpdateMode(false);
        setReports([]);
      }
    } catch (err) {
      setResponse({
        type: "error",
        message: err.message,
        open: true,
      });
    }
  };

  const getResults = () => {
    const results =
      _.find(reports[0]?.report || [], (rp) => search(rp))?.results || [];

    return results;
  };

  return (
    <div className="flex flex-col md:flex-row flex-1 md:space-y-0 space-y-2 md:space-x-2  p-3 max-h-[94vh] overflow-hidden">
      <div className="w-full h-1/5 overflow-y-auto  md:h-full md:w-1/5 border-r border-r-gray-300 bg-gray-100 p-2">
        {/* Students List */}

        {students.map((std, idx) => (
          <div
            onClick={() => selectStudent(std)}
            className={`${
              selected?.student_id === std.student_id
                ? "border-x-2 rounded-md border-green-500 bg-white"
                : "bg-white"
            } flex overflow-hidden items-center my-[2px] space-x-2 shadow-md rounded-sm p-1 cursor-pointer`}
            key={idx}
          >
            <img
              src={std?.photo}
              alt={std?.last_name}
              className="w-10 h-10 object-cover"
            />
            <p className="truncate">
              {std?.last_name + " " + std?.other_names}
            </p>
          </div>
        ))}
      </div>
      {/* Selected Students with subjects score */}
      <div className="flex-1 flex  overflow-scroll">
        <form onSubmit={uploadResults} className="flex-1 p-2 ">
          <div className="flex items-center justify-between bg-white w-full p-2">
            <div className="flex gap-2 items-center">
              <img
                src={selected?.photo}
                className="w-10 h-10 object-cover"
                alt={selected?.last_name}
              />
              <div className="p-1 overflow-hidden   ">
                <h1 className="truncate font-bold">
                  {selected?.last_name + " " + selected?.other_names}
                </h1>
                <h3 className="text-sm">
                  Report ({settings.currentTerm}) - {settings.academicYear}
                </h3>
              </div>
            </div>
            {profile?.class_assigned === "Basic 9" && (
              <select
                value={testType}
                onChange={(e) => setTestType(e.target.value)}
                className="w-fit p-2 bg-gray-300 cursor-pointer"
              >
                <option value="">-- Exams Type --</option>
                <option value="Mock Exams"> Mock 1 </option>
                <option value="Mock Exams 2"> Mock 2</option>
                <option value="Mock Exams 3"> Mock 3 </option>
                <option value="Mock Exams 4"> Mock 4 </option>
                <option value="Mock Exams 5"> Mock 5 </option>
                <option value="Mock Exams 6"> Mock 6 </option>
              </select>
            )}
          </div>
          <div
            ref={form}
            className="flex p-2 bg-white justify-start w-full flex-wrap"
          >
            {SUBJECTS.map((crs, idx) => (
              <div className="flex flex-col w-1/2  p-2" key={idx}>
                <label htmlFor={idx} className="text-sm truncate">
                  {crs.text}
                </label>
                <input
                  className="p-2 border"
                  // value={findScore(crs.value)}
                  onChange={(e) => getScore(crs.value, e.target.value, crs.isCore)}
                  type="number"
                  min={0}
                  max={100}
                  name={crs.value}
                  id={idx}
                />
              </div>
            ))}
          </div>
          <input
            value={remarks}
            name="remarks"
            onChange={(e) => setRemarks(e.target.value)}
            type="text"
            className="p-2 border w-full mx-4 my-3"
            placeholder="Head Teacher's Remarks"
          />
          <button
            className="p-2 mx-2 rounded-sm text-sm bg-gray-600 text-white"
            type="submit"
          >
            {loading ? <Spin /> : "Upload Results"}
          </button>
        </form>

        {selected !== null && (
          <div className="hidden md:flex flex-col w-2/5 bg-white p-2">
            <div className="h-full overflow-scroll">
              <table className="table-fixed w-full">
                <thead className="sticky top-0 bg-white z-50">
                  <tr className="border">
                    <td colSpan={2} className="p-2">
                      Term - Academic Year ({selected?.current_class})
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 font-bold border">Subject</td>
                    <td className="p-2 font-bold border">Score</td>
                  </tr>
                </thead>
                <tbody>
                  {getResults()?.map((info, idx) => (
                    <tr
                      className={`border text-sm ${
                        idx % 2 === 0 ? "bg-gray-50" : ""
                      }`}
                      key={idx}
                    >
                      <td className="border p-2 truncate">{info.subject}</td>
                      <td className="border p-2">{info.mark}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {response.open && (
        <Feedback
          message={response.message}
          type={response.type}
          setOpen={() => setResponse({ open: false })}
        />
      )}
    </div>
  );
}

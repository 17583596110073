import _ from "lodash";
import { classes } from "../assets/data/classes";
// this is temporary.
import {signature} from '../assets/images/data-uri/head-sign'
export const reportSheet = (ccreport, csrp) => {
  
  const getNextClass = () => {
    const currentClassIndex = classes.findIndex(c => c.value === csrp.current_class);
    return classes[currentClassIndex + 1].value.toUpperCase()
  }

  const getCoursePosition = (total_score, all_scores) => {
    const uniqueScores = _.uniq(all_scores);
    const grade_position = parseInt(uniqueScores.indexOf(total_score) + 1);
    //getting grade with index values

    if (_.compact(all_scores.length) === 0){
      return ""
    }

    if (grade_position.toString().endsWith("1") && grade_position !== 11) {
      return grade_position.toString() + "st";
    } else if (
      grade_position.toString().endsWith("2") &&
      grade_position !== 12
    ) {
      return grade_position.toString() + "nd";
    } else if (
      grade_position.toString().endsWith("3") &&
      grade_position !== 13
    ) {
      return grade_position.toString() + "rd";
    } else {
      return grade_position.toString() + "th";
    }
  };

  return [
    {
      image: csrp.photoURI,
      fit: [120, 80],
      absolutePosition: { x: 495, y: 40 },
    },
    {
      text: csrp.student_id,
      fontSize: 10,
      absolutePosition: { x: 495, y: 130 },
    },
    {
      text: "Term: " + csrp.term,
      absolutePosition: { x: 20, y: 160 },
    },
    {
      text: "Class: " + csrp.current_class,
      absolutePosition: { x: 20, y: 190 },
    },
    {
      text:
        "Position: " +
        getCoursePosition(csrp.overrall_aggregate, ccreport.overrall),
      absolutePosition: { x: 320, y: 160 },
    },
    {
      text: "Next Term Begins: " + csrp.next_term,
      absolutePosition: { x: 320, y: 190 },
    },
    {
      text: "Academic Year: " + csrp.academic_year,
      absolutePosition: { x: 20, y: 220 },
    },
    {
      text: "Total Score: " + csrp.overrall_aggregate,
      absolutePosition: { x: 320, y: 220 },
    },
    {
      text:
        "Average Score: " +
        Number(csrp.overrall_aggregate / csrp.n_courses).toFixed(2),
      absolutePosition: { x: 20, y: 250 },
    },
    {
      text: "No on Roll: " + csrp.num_on_roll,
      absolutePosition: { x: 320, y: 250 },
    },
    {
      text: "Name: " + csrp.last_name + "  " + csrp.other_names,
      absolutePosition: { x: 20, y: 130 },
    },
    {
      absolutePosition: { x: 20, y: 290 },
      table: {
        widths: [100, 60, 70, 60, 70, 50, 90],
        heights: 20,
        fontSize: 10,
        body: [
          [
            { text: "COURSES", alignment: "center", bold: true },
            { text: "CLASS\nSCORE", alignment: "center", bold: true },
            { text: "EXAMS\nSCORE", alignment: "center", bold: true },
            { text: "TOTAL\nSCORE", alignment: "center", bold: true },
            { text: "CLASS\nPOSITION", alignment: "center", bold: true },
            { text: "GRADE", alignment: "center", bold: true },
            { text: "REMARKS", alignment: "center", bold: true },
          ],
          [
            { text: "Mathematics", bold: true },
            { text: csrp.mcs * 0.5, alignment: "center" },
            { text: csrp.mes * 0.5, alignment: "center" },
            { text: csrp.mts, alignment: "center" },
            {
              text: getCoursePosition(csrp.mts, ccreport.maths),
              alignment: "center",
            },
            { text: csrp.mgr, alignment: "center" },
            { text: csrp.mrm, alignment: "center" },
          ],
          [
            { text: "Int. Science", bold: true },
            { text: csrp.scs * 0.5, alignment: "center" },
            { text: csrp.ses * 0.5, alignment: "center" },
            { text: csrp.sts, alignment: "center" },
            {
              text: getCoursePosition(csrp.sts, ccreport.science),
              alignment: "center",
            },
            { text: csrp.sgr, alignment: "center" },
            { text: csrp.srm, alignment: "center" },
          ],
          [
            { text: "English Lang.", bold: true },
            { text: csrp.ecs * 0.5, alignment: "center" },
            { text: csrp.ees * 0.5, alignment: "center" },
            { text: csrp.ets, alignment: "center" },
            {
              text: getCoursePosition(csrp.ets, ccreport.english),
              alignment: "center",
            },
            { text: csrp.egr, alignment: "center" },
            { text: csrp.erm, alignment: "center" },
          ],
          [
            {
              text:
                (csrp.class_category !== "Junior High"
                  && csrp.owcs !== 0) ? "O W O P" : csrp.class_category === "Junior High" ? "Social Studies"
                  : " ",
              bold: true,
            },
            { text: csrp.owcs * 0.5 || " ", alignment: "center" },
            { text: csrp.owes * 0.5 || " ", alignment: "center" },
            { text: csrp.owts || " ", alignment: "center" },
            {
              text: csrp.owts ? getCoursePosition(csrp.owts, ccreport.owop): " ",
              alignment: "center",
            },
            { text:  csrp.owts  ? csrp.owgr : " ", alignment: "center" },
            { text:  csrp.owts  ? csrp.owrm : "  ", alignment: "center" },
          ],
          [
            { text: "Rel. & Moral Edu.", bold: true },
            { text: csrp.rmcs * 0.5, alignment: "center" },
            { text: csrp.rmes * 0.5, alignment: "center" },
            { text: csrp.rmts, alignment: "center" },
            {
              text: getCoursePosition(csrp.rmts, ccreport.rme),
              alignment: "center",
            },
            { text: csrp.rmgr, alignment: "center" },
            { text: csrp.rmrm, alignment: "center" },
          ],
          [
            { text: "Computing", bold: true },
            { text: csrp.ictcs * 0.5, alignment: "center" },
            { text: csrp.ictes * 0.5, alignment: "center" },
            { text: csrp.ictts, alignment: "center" },
            {
              text: getCoursePosition(csrp.ictts, ccreport.ict),
              alignment: "center",
            },
            { text: csrp.ictgr, alignment: "center" },
            { text: csrp.ictrm, alignment: "center" },
          ],
          [
            { text: "Fante", bold: true },
            { text: csrp.fncs * 0.5, alignment: "center" },
            { text: csrp.fnes * 0.5, alignment: "center" },
            { text: csrp.fnts, alignment: "center" },
            {
              text: getCoursePosition(csrp.fnts, ccreport.fantse),
              alignment: "center",
            },
            { text: csrp.fngr, alignment: "center" },
            { text: csrp.fnrm, alignment: "center" },
          ],
          [
            { text: "French", bold: true },
            { text: csrp.fcs * 0.5, alignment: "center" },
            { text: csrp.fes * 0.5, alignment: "center" },
            { text: csrp.fts, alignment: "center" },
            {
              text: getCoursePosition(csrp.fts, ccreport.french),
              alignment: "center",
            },
            { text: csrp.fgr, alignment: "center" },
            { text: csrp.frm, alignment: "center" },
          ],
          [
            { text: "Creative Arts", bold: true },
            { text: csrp.cracs * 0.5, alignment: "center" },
            { text: csrp.craes * 0.5, alignment: "center" },
            { text: csrp.crats, alignment: "center" },
            {
              text: getCoursePosition(csrp.crats, ccreport.creative_arts),
              alignment: "center",
            },
            { text: csrp.cragr, alignment: "center" },
            { text: csrp.crarm, alignment: "center" },
          ],
          [
            {
              text:
                csrp.class_category === "Junior High"
                  ? "Career Tech"
                  : "History",
              bold: true,
            },
            {
              text:
                csrp.class_category === "Junior High"
                  ? csrp.ctcs * 0.5
                  : csrp.hcs * 0.5,
              alignment: "center",
            },
            {
              text:
                csrp.class_category === "Junior High"
                  ? csrp.ctes * 0.5
                  : csrp.hes * 0.5,
              alignment: "center",
            },
            {
              text:
                csrp.class_category === "Junior High" ? csrp.ctts : csrp.hts,
              alignment: "center",
            },
            {
              text:
                csrp.class_category === "Junior High"
                  ? getCoursePosition(csrp.ctts, ccreport.ctech)
                  : getCoursePosition(csrp.hts, ccreport.history),
              alignment: "center",
            },
            {
              text:
                csrp.class_category === "Junior High" ? csrp.ctgr : csrp.hgr,
              alignment: "center",
            },
            {
              text:
                csrp.class_category === "Junior High" ? csrp.ctrm : csrp.hrm,
              alignment: "center",
            },
          ],
          [
            { text: csrp.phcs > 0 ? "PE" : " ", bold: true },
            { text: csrp.phcs * 0.5 || " ", alignment: "center" },
            { text: csrp.phes * 0.5 || " ", alignment: "center" },
            { text: csrp.phts || " ", alignment: "center" },
            {
              text:
                csrp.phcs !== 0
                  ? getCoursePosition(csrp.phts, ccreport.pe)
                  : " ",
              alignment: "center",
            },
            { text: csrp.phcs !== 0 ? csrp.phgr : " ", alignment: "center" },
            { text: csrp.phcs !== 0 ? csrp.phrm : " ", alignment: "center" },
          ],
        ],
      },
    },
    {
      text:
        "Attendance: " +
        csrp.attendance +
        "/" +
        csrp.total_days +
        "                                                             Promoted to: " + (csrp.term === "Third Term" ?  getNextClass() : " "),
      absolutePosition: { x: 20, y: 620 },
    },
    {
      text:
        "Attitude: " +
        csrp.attitude +
        "                              Interest: " +
        csrp.interest,
      absolutePosition: { x: 20, y: 650 },
    },
    {
      text: "Conduct: " + csrp.conduct,
      absolutePosition: { x: 20, y: 680 },
    },
    {
      text: "Class Teacher's Remarks: " + csrp.remarks,
      absolutePosition: { x: 20, y: 710 },
    },
    {
      text: "Head Teacher's Remarks: " + csrp.ht_remarks,
      absolutePosition: { x: 20, y: 740 },
    },
    { 
      image:signature, // head signature...
      absolutePosition: { x: 410, y: 760 },
      fit:[100, 100]
    },
    {
      text: "...........................................\nHead Teacher's Signature",
      absolutePosition: { x: 400, y: 770 },
    },
  ];
};

// this is temporary.
import { signature } from "../assets/images/data-uri/head-sign";

export const mockSheet = (report) => {
  return [
    {
      image: report.photoURI,
      fit: [120, 80],
      absolutePosition: { x: 495, y: 40 },
    },
    {
      text: "ID: " + report.profile?.id,
      fontSize: 10,
      absolutePosition: { x: 490, y: 130 },
    },
    {
      text: "Class: " + report.profile?.class,
      absolutePosition: { x: 20, y: 160 },
    },
    {
      text: "Academic Year: " + report.academicYear,
      absolutePosition: { x: 20, y: 220 },
    },
    {
      text: "Raw Score: " + report.rawScore,
      absolutePosition: { x: 20, y: 190 },
    },
    {
      text: "Aggregate: " + report?.aggregate,
      absolutePosition: { x: 20, y: 250 },
    },
    {
      text: report.profile?.name,
      absolutePosition: { x: 20, y: 130 },
    },
    {
      text: report.profile?.description,
      fontSize: 18,
      absolutePosition: { x: 20, y: 290 },
    },
    {
      absolutePosition: { x: 20, y: 320 },
      table: {
        widths: [200, "*", "*", "*"],
        heights: 20,
        fontSize: 10,
        body: report.results,
      },
    },

    {
      text: "Head Teacher's Remarks: " + report.profile?.remarks,
      absolutePosition: { x: 20, y: 640 },
    },
    {
      image: signature, // head signature...
      absolutePosition: { x: 410, y: 760 },
      fit: [100, 100],
    },
    {
      text: "...........................................\nHead Teacher's Signature",
      absolutePosition: { x: 400, y: 770 },
    },
  ];
};

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Students from "./pages/Students";
import Attendance from "./pages/Attendance";
import TimeTable from "./pages/TimeTable";
import Midterm from "./pages/Midterm";
import ResetPassword from "./pages/ResetPassword";
import Examination from "./pages/Examination";
import Reports from "./pages/Reports";
import { Private } from "./routes";
import Assignments from "./pages/Homework";
import Register from "./pages/Register";
import Board from "./pages/Board";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/start" element={<Board />} exact>
          <Route path="" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="reset" element={<ResetPassword />} exact />
        </Route>

        <Route path="/" element={<Private Child={Dashboard} />} exact />
        <Route path="/students" element={<Private Child={Students} />} exact />
        <Route
          path="/attendance"
          element={<Private Child={Attendance} />}
          exact
        />
        <Route
          path="/time-table"
          element={<Private Child={TimeTable} />}
          exact
        />
        <Route path="/mid-term" element={<Private Child={Midterm} />} exact />
        <Route
          path="/examination"
          element={<Private Child={Examination} />}
          exact
        />
        <Route
          path="/exam-reports"
          element={<Private Child={Reports} />}
          exact
        />
        <Route
          path="/homeworks"
          element={<Private Child={Assignments} />}
          exact
        />
      </Routes>
    </Router>
  );
}

export default App;

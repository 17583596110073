import {
  conducts,
  remarks,
  attitudes,
  interests,
} from "../assets/data/remarks";
import _ from "lodash";
export const getAttendance = (attendance, student_id, term, cls) => {
  let attendStatus = attendance.filter((atd) => {
    return (
      atd.term === term &&
      atd.year === new Date().getFullYear() &&
      atd.class === cls
    );
  });

  let totalPresent = 0;

  if (attendStatus.length < 1) {
    return 0;
  } else {
    for (var status in attendStatus) {
      for (var p in attendStatus[status].data) {
        if (attendStatus[status].data[p].student_id === student_id) {
          totalPresent += 1;
        }
      }
    }
    return totalPresent;
  }
};

export const getGrade = (tscore) => {
  if (tscore >= 90) {
    return "A";
  } else if (tscore >= 80 && tscore <= 89.99) {
    return "B";
  } else if (tscore >= 60 && tscore <= 79.99) {
    return "C";
  } else if (tscore >= 50 && tscore <= 59.99) {
    return "D";
  } else if (tscore >= 40 && tscore <= 49.99) {
    return "E";
  } else {
    return "F";
  }
};

export const checkFile = (file) => {
  const fileType = file.type;

  const fileSize = file.size / 1024;
  const accepts = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];

  return accepts.includes(fileType) && fileSize <= 2 * 1024 * 1024;
};

export const getRemarks = (grade) => {
  if (grade === "A") {
    return "Excellent";
  } else if (grade === "B") {
    return "Very Good";
  } else if (grade === "C") {
    return "Good";
  } else if (grade === "D") {
    return "Credit";
  } else if (grade === "E") {
    return "Pass";
  } else {
    return "Fail";
  }
};

export const randomRange = (min, max) => {
  return Math.floor(
    Math.random() * (parseInt(max) - parseInt(min) + 1) + parseInt(min)
  );
};

export const generateRemarks = () => {
  return {
    conduct: conducts[randomRange(0, conducts.length - 1)],
    attitude: attitudes[randomRange(0, attitudes.length - 1)],
    interest: interests[randomRange(0, interests.length - 1)],
    remarks: remarks[randomRange(0, remarks.length - 1)],
  };
};

export const getPercentage = (numGrades, total) => {
  const percentage = Number(Math.round(parseFloat(numGrades / total) * 100));

  return isNaN(percentage) ? "n/a" : percentage.toString() + " %";
};

export const getGrades = (course, data, term) => {
  const _cc = _.filter(data, (cc) => cc.term === term);

  let getAs = 0,
    getBs = 0,
    getCs = 0,
    getDs = 0,
    getEs = 0,
    getFs = 0;

  if (course === "Mathematics") {
    getAs = _.filter(_cc, (cc) => {
      return cc.mgr === "A";
    }).length;
    getBs = _.filter(_cc, (cc) => {
      return cc.mgr === "B";
    }).length;
    getCs = _.filter(_cc, (cc) => {
      return cc.mgr === "C";
    }).length;
    getDs = _.filter(_cc, (cc) => {
      return cc.mgr === "D";
    }).length;
    getEs = _.filter(_cc, (cc) => {
      return cc.mgr === "E";
    }).length;
    getFs = _.filter(_cc, (cc) => {
      return cc.mgr === "F";
    }).length;
  } else if (course === "English Language") {
    getAs = _.filter(_cc, (cc) => {
      return cc.egr === "A";
    }).length;
    getBs = _.filter(_cc, (cc) => {
      return cc.egr === "B";
    }).length;
    getCs = _.filter(_cc, (cc) => {
      return cc.egr === "C";
    }).length;
    getDs = _.filter(_cc, (cc) => {
      return cc.egr === "D";
    }).length;
    getEs = _.filter(_cc, (cc) => {
      return cc.egr === "E";
    }).length;
    getFs = _.filter(_cc, (cc) => {
      return cc.egr === "F";
    }).length;
  } else if (course === "Integrated Science") {
    getAs = _.filter(_cc, (cc) => {
      return cc.sgr === "A";
    }).length;
    getBs = _.filter(_cc, (cc) => {
      return cc.sgr === "B";
    }).length;
    getCs = _.filter(_cc, (cc) => {
      return cc.sgr === "C";
    }).length;
    getDs = _.filter(_cc, (cc) => {
      return cc.sgr === "D";
    }).length;
    getEs = _.filter(_cc, (cc) => {
      return cc.sgr === "E";
    }).length;
    getFs = _.filter(_cc, (cc) => {
      return cc.sgr === "F";
    }).length;
  } else if (course === "Religious & Moral Edu.") {
    getAs = _.filter(_cc, (cc) => {
      return cc.rmgr === "A";
    }).length;
    getBs = _.filter(_cc, (cc) => {
      return cc.rmgr === "B";
    }).length;
    getCs = _.filter(_cc, (cc) => {
      return cc.rmgr === "C";
    }).length;
    getDs = _.filter(_cc, (cc) => {
      return cc.rmgr === "D";
    }).length;
    getEs = _.filter(_cc, (cc) => {
      return cc.rmgr === "E";
    }).length;
    getFs = _.filter(_cc, (cc) => {
      return cc.rmgr === "F";
    }).length;
  } else if (course === "History") {
    getAs = _.filter(_cc, (cc) => {
      return cc.hgr === "A";
    }).length;
    getBs = _.filter(_cc, (cc) => {
      return cc.hgr === "B";
    }).length;
    getCs = _.filter(_cc, (cc) => {
      return cc.hgr === "C";
    }).length;
    getDs = _.filter(_cc, (cc) => {
      return cc.hgr === "D";
    }).length;
    getEs = _.filter(_cc, (cc) => {
      return cc.hgr === "E";
    }).length;
    getFs = _.filter(_cc, (cc) => {
      return cc.hgr === "F";
    }).length;
  } else if (course === "French") {
    getAs = _.filter(_cc, (cc) => {
      return cc.fgr === "A";
    }).length;
    getBs = _.filter(_cc, (cc) => {
      return cc.fgr === "B";
    }).length;
    getCs = _.filter(_cc, (cc) => {
      return cc.fgr === "C";
    }).length;
    getDs = _.filter(_cc, (cc) => {
      return cc.fgr === "D";
    }).length;
    getEs = _.filter(_cc, (cc) => {
      return cc.fgr === "E";
    }).length;
    getFs = _.filter(_cc, (cc) => {
      return cc.fgr === "F";
    }).length;
  } else if (course === "Fantse") {
    getAs = _.filter(_cc, (cc) => {
      return cc.fngr === "A";
    }).length;
    getBs = _.filter(_cc, (cc) => {
      return cc.fngr === "B";
    }).length;
    getCs = _.filter(_cc, (cc) => {
      return cc.fngr === "C";
    }).length;
    getDs = _.filter(_cc, (cc) => {
      return cc.fngr === "D";
    }).length;
    getEs = _.filter(_cc, (cc) => {
      return cc.fngr === "E";
    }).length;
    getFs = _.filter(_cc, (cc) => {
      return cc.fngr === "F";
    }).length;
  } else if (course === "Our World Our People") {
    getAs = _.filter(_cc, (cc) => {
      return cc.owgr === "A";
    }).length;
    getBs = _.filter(_cc, (cc) => {
      return cc.owgr === "B";
    }).length;
    getCs = _.filter(_cc, (cc) => {
      return cc.owgr === "C";
    }).length;
    getDs = _.filter(_cc, (cc) => {
      return cc.owgr === "D";
    }).length;
    getEs = _.filter(_cc, (cc) => {
      return cc.owgr === "E";
    }).length;
    getFs = _.filter(_cc, (cc) => {
      return cc.owgr === "F";
    }).length;
  }

  return {
    number: [getAs, getBs, getCs, getDs, getEs, getFs],
    percentage: [
      getPercentage(getAs, _cc.length),
      getPercentage(getBs, _cc.length),
      getPercentage(getCs, _cc.length),
      getPercentage(getDs, _cc.length),
      getPercentage(getEs, _cc.length),
      getPercentage(getFs, _cc.length),
    ],
  };
};

// related to BECE Report
export const findGrade = (mark) => {
  if (mark < 41) {
    return 9;
  }
  if (mark >= 41 && mark < 50) {
    return 8;
  }
  if (mark >= 50 && mark < 56) {
    return 7;
  }
  if (mark >= 56 && mark < 60) {
    return 6;
  }
  if (mark >= 60 && mark < 70) {
    return 5;
  }

  if (mark >= 70 && mark <= 79) {
    return 4;
  }

  if (mark >= 80 && mark < 85) {
    return 3;
  }

  if (mark >= 85 && mark < 91) {
    return 2;
  }

  if (mark >= 91) {
    return 1;
  }
};

export const findRemarks = (grade) => {
  switch (grade) {
    case 1:
      return "Excellent";
    case 2:
      return "Very Good";
    case 3:
      return "Good";
    case 4:
      return "High Average";
    case 5:
      return "Average";
    case 6:
      return "Low Average";

    case 7:
      return "Low";
    case 8:
      return "Lower";

    default:
      return "Lowest";
  }
};

export const calculateAggregate = (examData) => {
  let coreSubjects = examData.filter((cr) => cr.isCore === true);
  let nonCoreSubjects = examData
    .filter((cr) => cr.isCore === false || cr.isCore === undefined)
    .sort((a, b) => a - b)
    .slice(0, 2);

  let coreGrade = 0;
  let nonCoreGrade = 0;

  coreSubjects.map((mc) => {
    return (coreGrade += findGrade(mc.mark));
  });

  nonCoreSubjects.map((mc) => {
    return (nonCoreGrade += findGrade(mc.mark));
  });

  let aggregate = coreGrade + nonCoreGrade;
  return aggregate;
};
